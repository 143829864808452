/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface TextWithMultipleImagesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const StyledTextWithMultipleImages = styled.section<{ background: string }>`
  ${({ background, theme }) =>
    background === 'light' &&
    css`
      background-color: ${theme.color.light};
    `}
  ${({ background, theme }) =>
    background === 'dark' &&
    css`
      background-color: ${theme.color.dark};
    `}
  ${({ background, theme }) =>
    background === 'grey' &&
    css`
      background-color: ${theme.color.grey};
    `}

  ${({ background, theme }) =>
    background === 'primary' &&
    css`
      background-color: ${theme.color.primary};
    `}
`

const Content = styled(ParseContent)`
  & p {
    font-size: 16px;
    line-height: 30px;
  }
`

const Title = styled(ParseContent)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 50px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  & h3 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 30px;
    line-height: 30px;
  }

  @media screen and (max-width: 575px) {
    & h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const TextWithMultipleImages: React.FC<TextWithMultipleImagesProps> = ({
  fields,
}) => (
  <StyledTextWithMultipleImages
    className="py-lg-3"
    background={fields.background || 'light'}
  >
    <div className="container py-lg-5 py-3">
      {fields.title && <Title content={fields.title || ''} />}
      <div className="py-lg-5 py-3 d-flex justify-content-evenly">
        {fields.images?.map((image, index) => (
          <div className="px-lg-5 px-sm-3" key={index}>
            <Plaatjie image={image?.image} alt="" />
          </div>
        ))}
      </div>
      <div className={`row justify-content-${fields.alignment}`}>
        <div className={`col-lg-${fields.width}`}>
          <Content content={fields.description || ''} />
        </div>
      </div>
    </div>
  </StyledTextWithMultipleImages>
)

export default TextWithMultipleImages
