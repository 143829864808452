import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const StyledText = styled.section<{ background: string }>`
  ${({ background, theme }) =>
    background === 'light' &&
    css`
      background-color: ${theme.color.light};
    `}
  ${({ background, theme }) =>
    background === 'dark' &&
    css`
      background-color: ${theme.color.dark};
    `}
  ${({ background, theme }) =>
    background === 'grey' &&
    css`
      background-color: ${theme.color.grey};
    `}

  ${({ background, theme }) =>
    background === 'primary' &&
    css`
      background-color: ${theme.color.primary};
    `}
`

const Content = styled(ParseContent)`
  & p {
    font-size: 16px;
    line-height: 30px;
  }

  & img {
    object-fit: contain !important;
  }

  & h2 {
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    line-height: 20px;
  }

  & .gatsby-image-wrapper {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 991px) {
    & .gatsby-image-wrapper {
      margin-bottom: 0;
    }
  }
`

const Title = styled(ParseContent)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 50px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  & h3 {
    font-size: 30px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media screen and (max-width: 575px) {
    & h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const Text: React.FC<TextProps> = ({ fields }) => (
  <StyledText className="py-lg-3" background={fields.background || 'light'}>
    <div className="container py-lg-5 py-md-3 py-2">
      {fields.title && (
        <Title className="pb-lg-5 pb-md-4 pb-2" content={fields.title || ''} />
      )}
      <div className={`row justify-content-${fields.alignment}`}>
        <div className={`col-lg-${fields.width}`}>
          <Content content={fields.description || ''} />
        </div>
      </div>
    </div>
  </StyledText>
)

export default Text
