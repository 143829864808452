import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled, { css } from 'styled-components'

interface TextWithTitleProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const StyledTextWithTitle = styled.section<{ background: string }>`
  ${({ background, theme }) =>
    background === 'light' &&
    css`
      background-color: ${theme.color.light};
    `}
  ${({ background, theme }) =>
    background === 'dark' &&
    css`
      background-color: ${theme.color.dark};
    `}
  ${({ background, theme }) =>
    background === 'grey' &&
    css`
      background-color: ${theme.color.grey};
    `}

  ${({ background, theme }) =>
    background === 'primary' &&
    css`
      background-color: ${theme.color.primary};
    `}
  padding: 30px 0;

  @media screen and (max-width: 991px) {
    padding: 15px 0;
  }
`

const Content = styled(ParseContent)`
  & h3 {
    padding-left: 20px;
    font-size: 25px;
    line-height: 25px;
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    font-family: ${({ theme }) => theme.font.family.secondary};
  }

  & p {
    font-size: 14px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  max-width: 63px;
`

const TextWithTitle: React.FC<TextWithTitleProps> = ({ fields }) => (
  <StyledTextWithTitle background={fields.background || 'light'}>
    <div className="container">
      <div className="row">
        <div className={`col-lg-${12 - (fields.width || 12)}`}>
          <div className="d-flex align-items-center">
            <StyledPlaatjie image={fields.icon} alt="" />
            <Content content={fields.title || ''} />
          </div>
        </div>
        <div className={`col-lg-${fields.width} pt-lg-0 pt-3`}>
          <Content content={fields.description || ''} />
        </div>
      </div>
    </div>
  </StyledTextWithTitle>
)

export default TextWithTitle
