import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled, { css } from 'styled-components'

interface TextWithImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Content = styled(ParseContent)`
  position: relative;
  z-index: 2;

  & h1,
  h2 {
    position: relative;
    font-size: 50px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    margin-bottom: 40px;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  & h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    font-size: 30px;
    line-height: 30px;
    padding-bottom: 20px;
  }

  & p {
    font-size: 16px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    padding-right: 150px;
  }

  & span + span {
    margin-left: 30px;
  }

  @media screen and (max-width: 1199px) {
    & p {
      padding-right: 50px;
    }

    & span + span {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 991px) {
    & h1,
    h2 {
      margin-bottom: 20px;
    }

    & span + span {
      margin-top: 15px;
    }
  }

  @media screen and (max-width: 575px) {
    & p {
      padding-right: 0;

      & > span {
        width: 100%;

        & > a {
          text-align: center;
        }
      }
    }

    & h1,
    h2 {
      font-size: 40px;
    }
  }
`

const AltContent = styled(ParseContent)`
  position: relative;
  z-index: 2;
  padding-left: 80px;

  & h1,
  h2 {
    position: relative;
    font-size: 50px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    margin-bottom: 40px;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  & h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    font-size: 30px;
    line-height: 30px;
    padding-bottom: 20px;
  }

  & p {
    font-size: 16px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  & span + span {
    margin-left: 30px;
  }

  @media screen and (max-width: 1199px) {
    padding-left: 40px;

    & span + span {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 991px) {
    padding-left: 0px;

    & h1,
    h2 {
      margin-bottom: 20px;
    }

    & span + span {
      margin-top: 15px;
    }
  }

  @media screen and (max-width: 575px) {
    & p {
      padding-right: 0;

      & > span {
        width: 100%;

        & > a {
          text-align: center;
        }
      }
    }

    & h1,
    h2 {
      font-size: 40px;
    }
  }
`

const StyledTextWithImage = styled.section<{ background: any }>`
  ${({ background, theme }) =>
    background === 'light' &&
    css`
      background-color: ${theme.color.light};
    `}
  ${({ background, theme }) =>
    background === 'dark' &&
    css`
      background-color: ${theme.color.dark};
    `}
  ${({ background, theme }) =>
    background === 'grey' &&
    css`
      background-color: ${theme.color.grey};
    `}

  ${({ background, theme }) =>
    background === 'primary' &&
    css`
      background-color: ${theme.color.primary};
    `}
  padding: 80px 0 100px 0;

  @media screen and (max-width: 991px) {
    padding: 30px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 15px 0;
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ fading: string }>`
  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 991px) {
    ${({ fading }) =>
      fading === 'true'
        ? css`
            position: absolute !important;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;
            height: 60%;
            width: auto;
            opacity: 0.3;

            & img {
              object-fit: contain !important;
            }
          `
        : css`
            width: 70%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;

            @media screen and (max-width: 575px) {
              width: 90%;
            }
          `}
  }
`

const TextWithImage: React.FC<TextWithImageProps> = ({ fields }) => (
  <StyledTextWithImage background={fields.background || 'light'}>
    <div className="container">
      <div className="row position-relative">
        {fields.alignment !== 'end' && (
          <div className={`col-lg-${fields.width} d-flex align-items-center`}>
            <Content content={fields.description || ''} />
          </div>
        )}
        <div
          className={`col-lg-${
            12 - (fields.width || 0)
          } d-flex align-items-center ${
            fields.fadedbackground === 'no' &&
            `${
              fields.alignment !== 'end'
                ? `pt-lg-0 pt-sm-5 pt-4`
                : `pb-lg-0 pb-sm-5 pb-4`
            }`
          } `}
        >
          <StyledPlaatjie
            fading={(fields.fadedbackground === 'yes').toString()}
            image={fields.image}
            alt=""
          />
        </div>
        {fields.alignment === 'end' && (
          <div className={`col-lg-${fields.width} d-flex align-items-center`}>
            <AltContent content={fields.description || ''} />
          </div>
        )}
      </div>
    </div>
  </StyledTextWithImage>
)

export default TextWithImage
